<aside class="aec">
    <div class="aec-i"></div>
    <ng-container *ngIf="isLoggedIn; else notLogin">
        <!-- <h3 id="-seja-um-tester-exclusivo-do-nosso-app-">🚀 Seja um Tester Exclusivo do Nosso App!</h3> -->
        <p>🚀 Tenha Acesso Antecipado ao App do Kore! Estamos empolgados em oferecer a você a oportunidade exclusiva de experimentar o nosso aplicativo atualizado na Google Play antes de todo mundo! 🎉</p>
        <p><strong>Insira seu email de cadastro na Google Play abaixo e seja um dos primeiros a explorar as novidades:</strong></p>
        <div>
            <form [formGroup]="form" (ngSubmit)="submit($event)">
                <mat-form-field [matTooltip]="'Informe seu email no google play'" class="full">
                    <input matInput placeholder="Email do google play" formControlName="google_play_user">
                </mat-form-field>
            </form>
        </div>
        <p><strong>Por que participar?</strong></p>
        <ul>
            <li><strong>Acesso antecipado:</strong> Experimente as melhorias e novas funcionalidades antes de todos.</li>
            <li><strong>Feedback valioso:</strong> Ajude-nos a tornar o app ainda melhor com suas sugestões.</li>
        </ul>
        <p><strong>Vamos juntos transformar a experiência!</strong></p>
        <div fxLayoutAlign="space-between center">
            <button mat-button mat-dialog-close (click)="close()">Agora não</button>
            <button mat-raised-button color="primary" [ae2ButtonLocker]="isLoading" (click)="submit()">Quero participar!</button>
        </div>
    </ng-container>
    <ng-template #notLogin>
        <p class="center">Você precisa estar logado para ter acesso a este link.</p>
        <div fxLayoutAlign="space-between center">
            <button mat-button mat-dialog-close (click)="close()">Agora não</button>
            <button mat-raised-button color="primary" (click)="loginOpen()">Quero logar</button>
        </div>
    </ng-template>
</aside>
