/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/header/header.component.ngfactory";
import * as i3 from "@angularecommerce/core/services/settings/settings.service";
import * as i4 from "@angular/http";
import * as i5 from "@angularecommerce/core/services/affiliate/affiliate.service";
import * as i6 from "./shared/components/header/header.component";
import * as i7 from "./shared/components/footer/footer.component.ngfactory";
import * as i8 from "./shared/components/footer/footer.component";
import * as i9 from "@angularecommerce/core/services/marketing/marketing.service";
import * as i10 from "@angularecommerce/core/services/feedback/feedback.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "@angular/common";
import * as i13 from "@angular/router";
import * as i14 from "./app.component";
import * as i15 from "@angularecommerce/core/services/activities/activities.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵprd(512, null, i3.Ae2SettingsService, i3.Ae2SettingsService, [i4.Http]), i1.ɵprd(512, null, i5.Ae2AffiliateService, i5.Ae2AffiliateService, []), i1.ɵdid(4, 114688, null, 0, i6.HeaderComponent, [i3.Ae2SettingsService, i5.Ae2AffiliateService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(2, 114688, null, 0, i8.FooterComponent, [i9.Ae2MarketingService, i10.Ae2FeedbackService, i11.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "fullPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i13.RouterOutlet, [i13.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeader; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = _co.showFooter; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i14.AppComponent, [i15.Ae2ActivitiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
