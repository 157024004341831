import { Component, OnInit } from '@angular/core';
import { Ae2CarouselConfig } from '@angularecommerce/core/components/carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  
  carouselConfig: Ae2CarouselConfig;

  open: boolean = false;
  constructor() {
  }

  ngOnInit(): void {
    const bannerSize = {
      xs: '414x600',
      sm: '768x886',
      md: '1024x1231',
      lg: '1920x945'
    };
    
    const ratios = {
      xs: '69:100',
      sm: '384:443',
      md: '16:19',
      lg: '128:63'
    };

    this.carouselConfig = new Ae2CarouselConfig();
    this.carouselConfig.useDottedOverLay = false;
    this.carouselConfig.bannerSize = bannerSize;
    this.carouselConfig.ratios = ratios;
    this.carouselConfig.useAutoPlayProgressBar = false;
    this.carouselConfig.intervalTime = 700000;
  }

}
