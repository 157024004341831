import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
import { HomeModule } from './features/home/home.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  // {
  //   path: 'login',
  //   loadChildren: 'app/features/login/login.module#LoginModule',
  //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  {
    path: 'online/class',
    loadChildren: 'app/features/online-class/online-class.module#OnlineClassModule'
  },

  {
    path: 'online/thank-you',
    loadChildren: 'app/features/online-class-thanks/online-class-thanks.module#OnlineClassThanksModule'
  },

  {
    path: 'online/already-connected',
    loadChildren: 'app/features/online-class-disconnected/online-class-disconnected.module#OnlineClassDisconnectedModule'
  },
  {
    path: 'categoria/:categoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'checkout',
    // canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'evento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades',
    loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/:slug/:id',
    loadChildren: 'app/features/activity/activity.module#ActivityModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/tickets/:slug/:id',
    loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/:slugatividade/:slugevento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'faq',
    loadChildren: 'app/features/faq/faq.module#FaqModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'termos-e-condicoes',
    loadChildren: 'app/features/termos/termos.module#TermosModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: 'app/features/policy/policy.module#PolicyModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'regimento-interno',
    loadChildren: 'app/features/bylaws/bylaws.module#BylawsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  // {
  //   path: 'depoimentos',
  //   loadChildren: 'app/features/testimonials/testimonials.module#TestimonialsModule',
  //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  // {
  //   path: 'lp/:slug',
  //   loadChildren: 'app/features/flat-pages/flat-pages.module#FlatPagesModule',
  //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  // {
  //   path: 'pacotes',
  //   loadChildren: 'app/features/packages/packages.module#PackagesModule',
  //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  {
    path: 'pacotes',
    loadChildren: 'app/features/packages-new/packages.module#PackagesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'pacotes/:city/:unit',
    loadChildren: 'app/features/packages-new/packages.module#PackagesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'sobre',
    loadChildren: 'app/features/about/about.module#AboutModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'unidades',
    loadChildren: 'app/features/studios/studios.module#StudiosModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  // {
  //   path: 'fullbody',
  //   loadChildren: 'app/features/fullbody/fullbody.module#FullBodyModule',
  //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  {
    path: 'kore',
    loadChildren: 'app/features/kore/kore.module#KoreModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'franquias',
    loadChildren: 'app/features/franchises/franchises.module#FranchisesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'unidades/:slug',
    loadChildren: 'app/features/studios/studios.module#StudiosModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'unidades/:slug/:id',
    loadChildren: 'app/features/unit/unit.module#UnitModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'unidades/:slug/:city/:id',
    loadChildren: 'app/features/unit/unit.module#UnitModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'aulas-tematicas',
    loadChildren: 'app/features/thematic/thematic.module#ThematicModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'revalidate',
    loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'recadastramento',
    loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'instrutor2/:slug/:id',
    loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'instrutores2',
    loadChildren: 'app/features/list-instructors/list-instructors.module#ListInstructorsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'carreiras',
    loadChildren: 'app/features/careers/careers.module#CareersModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'carreiras/:jobs',
    loadChildren: 'app/features/career/career.module#CareerModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'kore-eventos',
    loadChildren: 'app/features/events/events.module#EventsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'kore-eventos/:slug',
    loadChildren: 'app/features/events-page/events-page.module#EventsPageModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'artigo/:code',
    loadChildren: 'app/features/article/article.module#ArticleModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'regras/:code',
    loadChildren: 'app/features/rules/rules.module#RulesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'remover-conta',
    loadChildren: 'app/features/removal/removal.module#RemovalModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    HomeModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
