<ng-container *ngIf="!changeLocal">
  <div class="unitVelo">

      <div class="unitVelo-box" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap.xs="20px">
          <div fxLayout fxLayoutAlign="start flex-end" fxLayoutGap="5px">
              <span class="unitVelo-title">{{selectedUnit?.locationDetail?.name}}</span>
              <span class="unitVelo-change" (click)="changeLocation()">mudar cidade</span>
          </div>
          <div fxLayout fxLayoutAlign="end center" fxLayoutGap="10px">
              <!-- <span class="unitVelo-activity unitVelo-selectedActivity">bike</span> -->
              <ng-container *ngFor="let activity of activities">
                  <span class="unitVelo-activity" [class.unitVelo-selectedActivity]="activitySeleted == activity?.id" (click)="setActivity(activity)">{{activity?.name}}</span>
              </ng-container>

          </div>
      </div>

      <ng-container *ngFor="let unit of unitList">
          <a (click)="storeChange(unit)">
              <div class="unitVelo-item" [class.unitVelo-item-selected-fb]="selectedUnit?.id == unit?.id">
                  <span>{{unit?.name}}</span>
              </div>
          </a>
      </ng-container>

      <!-- <ng-container *ngIf="activitySeletedArticle?.slug">
          <ae2-article code="em-breve-{{activitySeletedArticle?.slug}}" (data)="viewData($event)">
              <ng-template ae2ArticleContent let-active="active" let-title="title" let-description="description">
                  <ng-container *ngIf="isArticle && active">
                      <hr>
                      <div class="defaultFont" [innerHTML]="transformText(description)"></div>
                  </ng-container>
              </ng-template>
          </ae2-article>
      </ng-container> -->

      <button class="buyButton" mat-button (click)="buyPackeages()">comprar aulas</button>

  </div>
</ng-container>

<ng-container *ngIf="changeLocal">
  <div class="unitVelo">

      <div class="unitVelo-box">
          <div fxLayoutAlign="start center" (click)="closeLocation()">
              <mat-icon>keyboard_arrow_left</mat-icon>
              <span class="unitVelo-titlee">{{selectedUnit?.locationDetail?.name}}</span>
          </div>
      </div>

      <ng-container *ngFor="let unit of unitGroup">
          <a (click)="changeLocationValue(unit)">
              <div class="unitVelo-item">
                  <span>{{unit?.location?.name}}</span>
              </div>
          </a>
      </ng-container>
     <!-- <ae2-article code="em-breve">
          <ng-template ae2ArticleContent let-active="active" let-title="title" let-description="description">
              <hr>
              <div class="defaultFont" [innerHTML]="transformText(description)"></div>
          </ng-template>
      </ae2-article> -->

  </div>
</ng-container>
