import { Component, OnInit } from '@angular/core';
import { Ae2ActivitiesService, Ae2ActivitiesServiceGetAllQuery } from '@angularecommerce/core/services/activities/activities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showFooter: boolean = true;
  showHeader: boolean = true;

  constructor(private ae2ActivitiesService: Ae2ActivitiesService) {

  }

  ngOnInit(): void {
    this._getActiviy();
  }

  _getActiviy(): void {
    const query = new Ae2ActivitiesServiceGetAllQuery();
    query.active = true;
    query.show_in_activities_page = true;
    const paginator = this.ae2ActivitiesService.getAll(query);
    paginator.getAllPages$().subscribe((res) => {
      res.results.map((item) => {
        if (item.slug === 'kore') {
          localStorage.setItem('activity-kore', JSON.stringify(item));
        }
      })
    })
  }
}
